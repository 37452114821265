import { render, staticRenderFns } from "./print.vue?vue&type=template&id=f6c2c970&scoped=true&"
import script from "./print.vue?vue&type=script&lang=js&"
export * from "./print.vue?vue&type=script&lang=js&"
import style0 from "./print.vue?vue&type=style&index=0&id=f6c2c970&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../yiweb/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f6c2c970",
  null
  
)

export default component.exports